import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import GoogleAds from "../components/google/ads"

const AdPage = () => (
  <Layout>
    <SEO title="Ad Test" />
    <div className="w-full lg:w-2/3 m-auto">
      <h2>Ad Box Test</h2>
      <p>
        Below is going to be an Ad Box which will hopefully use all existing
        space to display an ad.
      </p>
      <div
        id="ad"
        className="w-full h-48 p-3 border border-dashed border-gray-800 rounded-lg"
      >
        {/* 7899938100 */}
        <GoogleAds slot="7899938100" />
      </div>
    </div>
  </Layout>
)

export default AdPage
